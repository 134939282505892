function setCookie(c_name,value,exdays){
	var exdate=new Date();
	exdate.setDate(exdate.getDate() + exdays);
	var c_value=escape(value) + ((exdays===null) ? "" : "; expires="+exdate.toUTCString());
	document.cookie=c_name + "=" + c_value;
}

function floGetCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) === 0) return c.substring(name.length,c.length);
    }
    return "";
}

//  we consider screens larger than 760 not beeing mobile
var isNotMobile = window.matchMedia("only screen and (min-width: 760px)", window.devicePixelRatio );
//console.log(isMobile.matches);

(function(){

// Set the cookie for the retina devices
// the cookie is used later to serve appropriate image size
	if( document.cookie.indexOf('flo_device_pixel_ratio') == -1 && 'devicePixelRatio' in window && window.devicePixelRatio == 2 && isNotMobile ){

		var date = new Date();

		date.setTime( date.getTime() + 3600000 );

		document.cookie = 'flo_device_pixel_ratio=' + window.devicePixelRatio + ';' + ' expires=' + date.toUTCString() +'; path=/';

		//if cookies are not blocked, reload the page

		if(document.cookie.indexOf('flo_device_pixel_ratio') != -1) {

			window.location.reload();

		}

	} else if(document.cookie.indexOf('flo_device_pixel_ratio') != -1 && floGetCookie('flo_device_pixel_ratio') != window.devicePixelRatio){
        // delete the coockie if the saved cookie does not match the current device pixel reatio

        var dateO = new Date();
        dateO.setTime( dateO.getTime() - 3600000 ); // set a past date that will be used to make the cookie expired

        document.cookie = 'flo_device_pixel_ratio=' + window.devicePixelRatio + ';' + ' expires=' + dateO.toUTCString() +'; path=/';

        window.location.reload(); // reload the page after deletting the cookie
    }

    // skip-link-focus-fix.js

    var is_webkit = navigator.userAgent.toLowerCase().indexOf( 'webkit' ) > -1,
        is_opera  = navigator.userAgent.toLowerCase().indexOf( 'opera' )  > -1,
        is_ie     = navigator.userAgent.toLowerCase().indexOf( 'msie' )   > -1;

    if ( ( is_webkit || is_opera || is_ie ) && document.getElementById && window.addEventListener ) {
        window.addEventListener( 'hashchange', function() {
            var element = document.getElementById( location.hash.substring( 1 ) );

            if ( element ) {
                if ( ! /^(?:a|select|input|button|textarea)$/i.test( element.tagName ) ) {
                    element.tabIndex = -1;
                }

                element.focus();
            }
        }, false );
    }

    /**
    * navigation.js
    *
    * Handles toggling the navigation menu for small screens.
    */

    var container, button, menu;

    container = document.getElementById( 'site-navigation' );
    if ( ! container ) {
        return;
    }

    button = container.getElementsByTagName( 'button' )[0];
    if ( 'undefined' === typeof button ) {
        return;
    }

    menu = container.getElementsByTagName( 'ul' )[0];

    // Hide menu toggle button if menu is empty and return early.
    if ( 'undefined' === typeof menu ) {
        button.style.display = 'none';
        return;
    }

    menu.setAttribute( 'aria-expanded', 'false' );

    if ( -1 === menu.className.indexOf( 'nav-menu' ) ) {
        menu.className += ' nav-menu';
    }

    button.onclick = function() {
        if ( -1 !== container.className.indexOf( 'toggled' ) ) {
            container.className = container.className.replace( ' toggled', '' );
            button.setAttribute( 'aria-expanded', 'false' );
            menu.setAttribute( 'aria-expanded', 'false' );
        } else {
            container.className += ' toggled';
            button.setAttribute( 'aria-expanded', 'true' );
            menu.setAttribute( 'aria-expanded', 'true' );
        }
    };
})();

jQuery(document).ready(function() {

    var ipad = navigator.userAgent.match(/iPad/i);

    if(jQuery('.with-hero-slider').length > 0) {
        var y = jQuery(window).scrollTop();  //your current y position on the page
        if (ipad === 0 && y === 0) {
            jQuery(window).scrollTop(y + 40);
        }
    }
	jQuery('.flickr_badge_image').each(function(index){
		var x = index % 3;
		if(index !=1 && x == 2){
			jQuery(this).addClass('last');
		}
	});
	setTimeout(function(){
        flo_set_menu_logo_center_spacing();
	},1000);


    jQuery('.flo-social-hover').hover(
        function() {
            jQuery( this ).attr('src', jQuery( this ).data('imghover') );
        }, function() {
            jQuery( this ).attr('src', jQuery( this ).data('imgoriginal') );
        }
    );
    
    /*Tweets widget*/
    var delay = 4000; //millisecond delay between cycles
    jQuery(".dynamic .tweet_item").css('display', 'none');
    function cycleThru(variable, j) {
        var jmax = jQuery(variable + " div").length;
        jQuery(variable + " div:eq(" + j + ")")
            .css('display', 'block')
            .animate({opacity: 1}, 600)
            .animate({opacity: 1}, delay)
            .animate({opacity: 0}, 800, function () {
                if (j + 1 === jmax) {
                    j = 0;
                } else {
                    j++;
                }
                jQuery(this).css('display', 'none').animate({opacity: 0}, 10);
                cycleThru(variable, j);
            });
    }

    jQuery('.tweets').each(function (index, val) {
        //iterate through array or object
        var parent_tweets = jQuery(val).parent().attr('id');
        var actioner = '#' + parent_tweets + ' .tweets .dynamic .flo_twitter .slides_container';
        cycleThru(actioner, 0);
    });

    /*
     post via ajax the calculated distance between menu items for header type 4
     */
    function flo_update_header_logo_center_menu_items_margin( right_spacing_px, left_spacing_px ){
        jQuery.ajax({
            url: ajaxurl,
            data: '&action=header_logo_center_menu_items_margin&left_spacing='+left_spacing_px+'&right_spacing='+right_spacing_px+'&header_logo_center_menu_spacing_nonce='+flo_js_data.header_logo_center_menu_spacing_nonce,
            type: 'POST',
            cache: false,
            success: function (response) {
            }
        });
    }


    function flo_set_menu_logo_center_spacing(){

        if( jQuery('.logo_center').length ){

	        var fw;
	        if(flo_js_data.site_width === null){
		        fw = 1280;
	        }else{
		        fw = flo_js_data.site_width;
	        }
            // this will work only if the current window width is > that the site cintent sidth
            if( (jQuery(window).width() - 100) > parseInt(fw)){  //  70 is the padding for the main container
                setTimeout(function(){

                    var menu_wrapper_width, menu_items_logo_left_width = 0, menu_items_logo_right_width = 0, logo_width,
                        left_or_right_container_width, left_spacing, right_spacing, left_items_width = 0, right_items_width = 0,
                        nr_items_left = 0, nr_items_right = 0 ;

                    menu_wrapper_width =  jQuery('.logo_center .header_main-nav .header_main-nav_link').width();

                    jQuery( ".logo_center .header_main-nav .header_main-nav_link>li.left-logo-menu" ).each(function( index ) {
                        left_items_width += jQuery(this).outerWidth();
                        nr_items_left += 1;
                    });

                    jQuery( ".logo_center .header_main-nav .header_main-nav_link>li.right-logo-menu" ).each(function( index ) {
                        right_items_width += jQuery(this).outerWidth();
                        nr_items_right += 1;
                    });

                    logo_width = jQuery('li.menu-item.site-logo').outerWidth();


                    //logo_width = logo_width + 50 + 2; // 50px is the padding, and 2 px is the border

                    left_or_right_container_width = (menu_wrapper_width - logo_width)/2;

                    if( left_or_right_container_width > left_items_width && left_or_right_container_width > right_items_width ){
                        var error_adjustment = 1.5; // we may need that in case we have samo errors

                        left_spacing = (left_or_right_container_width - right_items_width )/nr_items_right;
                        left_spacing = left_spacing.toFixed(2) - error_adjustment;  // round up to 2 digits

                        right_spacing = (left_or_right_container_width - left_items_width)/nr_items_left;
                        right_spacing = right_spacing.toFixed(2) - error_adjustment;  // round up to 2 digits
                        right_spacing_px = right_spacing + 'px';
                        left_spacing_px = left_spacing + 'px';
                        if(left_spacing_px !== flo_js_data.header_logo_center_menu_left_spacing || right_spacing_px !== flo_js_data.header_logo_center_menu_right_spacing){
                            jQuery( ".logo_center .header_main-nav .header_main-nav_link>li.left-logo-menu" ).each(function( index ) {
                                jQuery(this).stop(true, false).animate({ marginRight: (right_spacing ) }, 500, "swing" );
                            });

                            jQuery( ".logo_center .header_main-nav .header_main-nav_link>li.right-logo-menu" ).each(function( index ) {
                                jQuery(this).stop(true, false).animate({ marginLeft: (left_spacing) }, 500, "swing" );

                            });
                            flo_update_header_logo_center_menu_items_margin( right_spacing_px, left_spacing_px );
                        }else{
	                        jQuery( ".logo_center .header_main-nav .header_main-nav_link>li.left-logo-menu" ).each(function( index ) {
		                        jQuery(this).stop(true, false).animate({ marginRight: (right_spacing ) }, 500, "swing" );
	                        });

	                        jQuery( ".logo_center .header_main-nav .header_main-nav_link>li.right-logo-menu" ).each(function( index ) {
		                        jQuery(this).stop(true, false).animate({ marginLeft: (left_spacing) }, 500, "swing" );

	                        });
                        }


                    }else{
                        jQuery('.logo_center .header_main-nav .header_main-nav_link').addClass('no-margin');
                    }

                }, 100);
            }else{
                jQuery( ".logo_center .header_main-nav .header_main-nav_link > li" ).removeAttr('style');
            }


        }


    }
    jQuery(window).on('resize  orientationChanged', function() {
        flo_set_menu_logo_center_spacing();
    });
//	flo_set_menu_logo_center_spacing();

    // Init FitVids to make the videos responsive
    jQuery(".video-element, .entry-content").fitVids();
});
// Cosmo send mail
function cosmoSendMail( form, container){
	jQuery('#cosmo-name').removeClass('invalid');
	jQuery('#cosmo-email').removeClass('invalid');

	jQuery(container).html('');

	//jQuery('#flo-loading').css('background','rgba(255,255,255,0.2)');
	//jQuery('#flo-loading').fadeIn('slow'); // loading effect
	jQuery.ajax({
		url: ajaxurl,
		data: '&action=cosmoSendContact&'+jQuery( form ).serialize(),
		type: 'POST',
		dataType: "json",
//      cache: false,
		success: function (json) {

			//jQuery('#flo-loading').fadeOut('slow'); // loading effect

			if(json.contact_name ){
				jQuery('#cosmo-name').addClass('invalid');
				jQuery(container).append(json.contact_name);
			}

			if(json.contact_email ){
				jQuery('#cosmo-email').addClass('invalid');
				jQuery(container).append(json.contact_email);
			}

			if(json.message ){
				jQuery('.flo-modal').fadeIn('slow');

				jQuery( form).find('input[type="text"], textarea').val('');

				setTimeout(function(){
					jQuery('.flo-modal').fadeOut('slow');
				},3000);
			}

		}

	});
}

