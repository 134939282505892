(function($) {

  function detectmob() { 
   if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) ){
      return true;
    }
   else {
      return false;
    }
  }

  var Porto = {
    // All pages
    'common': {
      init: function() {

        var stickyHeader = floConfig.enableStickyHeader ? floConfig.enableStickyHeader : false;
        var $mainHeader = $('.main-header');

        var masonryOn = $('.masonry-grid').length > 0;
        var isHeroSlider = $('.hero-slider').length > 0;


        if ($('a.back-to-top').length > 0) {
          $('a.back-to-top').click(function(event) {

            event.preventDefault();
            $('html, body').animate({
              scrollTop: 0,
             
             }, '1200');
          });
        }

        /**
        * Stiky header option
        **/
        if (stickyHeader === '1') {

          var $headerNav = $('.navigation-block'),
              headerHeight = $headerNav.height();

          $headerNav.wrap('<div class="sticky-wrap"></div>');

          $('.sticky-wrap').css('height', headerHeight);

          $headerNav.waypoint({
            handler: function(direction) {
             if (direction == 'down' &&  !$headerNav.hasClass('sticky')) {
                $headerNav.addClass('sticky');
             } else if($headerNav.hasClass('sticky')) {
                $headerNav.removeClass('sticky');
             }
            },

            offset: -100
          });
        }
        
        /**
        * Enable mobile menu 
        **/
        (function() {
          var $body = $('body');
              
          var $menuButton = $mainHeader.find('.mobile-elements a.open-menu');


          $menuButton.click(function(e) {
            e.preventDefault();

            $('body, html').animate({
              scrollTop: 0
            });

            $body.toggleClass('without-scroll');
            $mainHeader.toggleClass('show-mobile-menu');
          });
        })();

        /**
        * Dropdown hover fix on mobile devices
        **/
        if (detectmob()) {
          $mainHeader.find('.header_main-nav_link.menu-item-has-children > a').each(function(index, el) {

            $(this).click(function(event) {
              if (!$(this).parent().hasClass('active')) {
                event.preventDefault();

                $mainHeader.find('.header_main-nav_link.active').removeClass('active');

                $(this).parent().addClass('active');
              }
            });
          });
        }

        /**
        * Gallery slider init
        **/
        if ($('.gallery').length > 0) {
  	      if ($('.gallery.horizontal-long').length > 0) {
            setTimeout(function(){
              jQuery('.gallery.horizontal-long .slider').slick('slickGoTo',0);

            }, 5);
            
  		      $('.gallery.horizontal-long .slider').slick({
  			      variableWidth: true,
  			      infinite: true,
  			      slidesToShow: 1,
  			      lazyLoad: 'progressive',
  			      centerMode: true,
              responsive: [
                {
                  breakpoint: 780,
                  settings: {
                    variableWidth: false,
                    centerMode: false,
                    adaptiveHeight: true
                  }
                }
              ]
  		      });
  	      }

  	      if ($('.gallery.fixed-with-thumbs').length > 0) {

  		      $('.gallery.fixed-with-thumbs .slider').slick({
  			      slidesToShow: 1,
  			      slidesToScroll: 1,
  			      fade: true,
  			      asNavFor: '.thumbs-slider',
              adaptiveHeight: true,
              lazyLoad: 'progressive',

              responsive: [
                {
                  breakpoint: 780,
                  settings: {
                    fade: false,
                    lazyLoad: 'progressive',
                  }
                }
              ]
  		      });

  		      $('.gallery.fixed-with-thumbs .thumbs-slider').slick({
  			      slidesToShow: 7,
  			      slidesToScroll: 7,
  			      asNavFor: '.gallery.fixed-with-thumbs .slider',
  			      centerMode: true,
  			      arrows: false,
  			      focusOnSelect: true,
  			      swipe: false,
              responsive: [
                {
                  breakpoint: 780,
                  // settings: 'unslick'
                }
              ]
  		      });

  		      $('a.hide-thumbs').click(function(event) {
  			      event.preventDefault();

  			      $(this).toggleClass('hidden');

  			      $('ul.thumbs-slider').slideToggle('slow');
  		      });
  	      }
        }

        /**
        * Language select — selecter.js
        **/
        if ($('.language-select select').length > 0) {
          $('.language-select select').selecter({});
        }

        /**
        * Masonry init
        **/
        if (masonryOn) {
          $('.masonry-grid').each(function() {
            $(this).find('.row').masonry({
              "percentPosition": true
            });
          });
        }
     
        if (isHeroSlider) {

          /**
          * Dropdown menu position (up / down)
          **/
          var $navigationBlock = $('.main-header.with-hero-slider.navigation-bottom ').find('.navigation-block');

          if ($navigationBlock.length > 0) {
            var $navigationBlockOffsetTop = $navigationBlock.offset().top;

            var basicPosition = 'up';
            
            $navigationBlock.addClass(basicPosition);

            $(window).scroll(function(event) {
              var windowScrollTop = $(window).scrollTop(); 

              var position = $navigationBlockOffsetTop / 2 > windowScrollTop ? 'up' : 'down';

              if (!$navigationBlock.hasClass(position)) {

                $navigationBlock
                  .removeClass(basicPosition)
                  .addClass(position);

                basicPosition = position;
              }
            });
          }
          
          /**
          * HeroSlider init
          **/
          $('.hero-slider').each(function(index, el) {

            var $heroSlider = $(this),
                $slider = $heroSlider.find('.slider'),
                $menu = $('.navigation-block nav > ul > li > a');

            var sliderAutoplay = floConfig.autoplay == 1 ? true : false;
            var sliderFadeEffect = floConfig.sl_transition_effect == 'fade' ? true : false;
            var sliderAutoplaySpeed = floConfig.autoplay_speed ? floConfig.autoplay_speed : false;

            $slider.on('init', function(slick) {
                menu_for_slider_color($slider, $menu);
            });

            // Slider init
            $slider.slick({
              speed: 800,
              autoplay: sliderAutoplay,
              autoplaySpeed: sliderAutoplaySpeed,
              fade: sliderFadeEffect
            });

            

            // Scroll Down button
            $heroSlider.find('a.slide-down').click(function(event) {
              event.preventDefault();

              var heroSliderHeight = $heroSlider.outerHeight();
              var heroOffset = $heroSlider.offset();
              var heroScrollTop = heroOffset.top + heroSliderHeight;

              $('html, body').animate({
                  scrollTop: heroScrollTop
              }, 800);
            });

            // Background image check  
            if (floConfig.automatically_text_color == '1') {
              BackgroundCheck.init({
                targets: '.top-nav, .navigation-block, button.slick-prev, button.slick-next, .slide.with-image article.content',
                images: '.hero-slider .inner'
              });

              $slider.on('setPosition afterChange', function() {
                BackgroundCheck.refresh();
              });
            }
 
            $slider.on('afterChange  ', function(event, slick, currentSlide, nextSlide){
              menu_for_slider_color($slider, $menu); 
            });

            function menu_for_slider_color( $slider, $menu ){
              var curr_slider = $slider.find('.slick-current'),
                  menu_color = curr_slider.attr('data-menu-color');
            
              if ( menu_color !== undefined ) {
                $menu.css('color', menu_color).addClass('colored');
              }  else {
                $menu.css('color', '').removeClass('colored');
              }
            }

            //Video Slide
            var initVideo = function($slider, $video) { 
              

              setTimeout(function() {
                  if(jQuery('.slick-active').length && jQuery('.slick-active').attr('video-autoplay') !== undefined){
                      jQuery('.video-container').bind('DOMSubtreeModified', function(e){
                          setTimeout(function() {
                              if(jQuery('.video-container').hasClass('vjs-ended')){
                                  jQuery('a.close-button').click();
                              }

                          }, 1000);
                      });
                  }
                  

              }, 1000);

              $('header.main-header').addClass('video-active');
              $slider.addClass('video-active');
              $video.find('.video-block').floVideo('create');

              // Disable Autoplay when video is on
              if (sliderAutoplay) {
                $slider.slick('slickSetOption', "autoplay", false, false);
              }
            };

            var closeVideo = function($slider, $video) {
              $video.find('.video-block').floVideo('destroy');

              // Enable Autoplay after then video is hide
              if (sliderAutoplay) {
                $slider.slick('slickSetOption', 'autoplay', true, false);
              }

              $('header.main-header').removeClass('video-active');
              $slider.removeClass('video-active');
            };

            // Enable video slide autoplay on fist slide
            (function(slider) {
              var $initialSlide = $(slider.slick('getSlick').$slides[0]),
                  $video = $initialSlide.find('.video-block');

              if ($initialSlide.hasClass('video-slide') && $initialSlide.attr('video-autoplay') !== undefined) {
                initVideo(slider, $initialSlide);
              }
            })($slider);



            $heroSlider.find('.slide.video-slide').each(function(index, el) {
              var $video = $(this),
                  $playButton = $(this).find('.play-button'),
                  $closeButton = $(this).find('.close-button');

              $playButton.click(function(event) {
                event.preventDefault();
               
                initVideo($slider, $video);

                $('body').on('keyup', function(event) {
                  if (event.keyCode == 27) {
                    closeVideo($slider, $video);
              
                    $('body').off('keyup');
                  }
                });

                // close the video iframe when the video ends
                jQuery('.video-container').bind('DOMSubtreeModified', function(e){
                    setTimeout(function() {
                        if(jQuery('.video-container').hasClass('vjs-ended')){
                            jQuery('a.close-button').click();
                        }

                    }, 2000);
                });

              });

              $closeButton.click(function(event) {
                event.preventDefault();

                closeVideo($slider,   $video);
              });
            });

            $slider.on('beforeChange', function() {
              if ($slider.find('.video-block.video-active').length) {
                var $video = $slider.find('.video-block.video-active').closest('.slide.video-slide');
                closeVideo($slider, $video);
              }
            });

            $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
              var $nextSlide = $(slick.$slides[nextSlide]);

              if ($nextSlide.hasClass('video-slide') && $nextSlide.attr('video-video-block') !== undefined) {
                initVideo($slider, $nextSlide);
              }
            });

          });
        }

        /**
        * Init hamburger button for hamburger menu
        **/
        if (floConfig.headerSlideDown) {
          $('header.main-header a.menu-open').click(function(e) {
            e.preventDefault();

            $(this).toggleClass('active');

            $('header.main-header.menu-slide-down .header_main-nav').slideToggle();
          });
        }
   
        /**
        * Horizontal slider init
        **/
        if ($('.block.horizontal-slider').length > 0 ) {
            var NearBySliderAutoplay = floConfig.autoplay == 1 ? true : false;
            var NearBySliderAutoplaySpeed = floConfig.autoplay_speed ? floConfig.autoplay_speed : false;

          $('.block.horizontal-slider .slider').slick({
            slidesToShow: 1,
            variableWidth: true,
            infinite: true,
            centerMode: true,
            autoplay: NearBySliderAutoplay,
            autoplaySpeed: NearBySliderAutoplaySpeed
          });
        }

        /**
        * Video Block init (up / down)
        **/
        if ($('.block.video-element').length > 0) {
          $('.block.video-element').each(function() {
            var $block = $(this),
                $video = $block.find('.video-block'),
                $playButton = $block.find('.title'),
                $closeButton = $block.find('a.close');

            $playButton.click(function(event) {
              event.preventDefault();

              $video.floVideo('create');
              $block.addClass('active');
            });

            $closeButton.click(function(event) {
              event.preventDefault();

              $video.floVideo('destroy');
              $block.removeClass('active');
            });


          });
        } 


        /**
        * Fancybox init
        **/
        if($("a[data-fancybox-group^='prettyPhoto']").length > 0){
          $("a[data-fancybox-group^='prettyPhoto']").fancybox({
              openEffect : 'elastic',
              openSpeed  : 150,
              closeEffect : 'elastic',
              closeSpeed  : 150,
              closeClick : true,
              padding: 0,
              autoCenter: false,
              openMethod : 'changeIn',
              wrapCSS: 'flo-fancybox',

              helpers: {
                  overlay: {
                    locked: false
                  }
              },
          });
        }
        var $video_container = $('#popup_slider');
        $('.item').each(function(){


          $(this).find('a.popup_play_button').on('click', function(event) {
            event.stopImmediatePropagation();
            var src = $(this).parent().data('video-url');
            $video_container.data('video-url', src).show().floVideo('create', true);
            return false;
          });
        });

        var closePopup = function(){
          $video_container.hide().removeClass('video-active').floVideo('destroy');
          $video_container.data('plugin_floVideo', {}).data('videoObj', {});
          $('header.main-header').removeClass('video-active');

        };

        jQuery('a.close-button').click(function(event) {
          event.preventDefault();
          closePopup();
        });

        $('body').on('keyup', function(event) {
          if (event.keyCode == 27) {
            closePopup();
          }
        });
        setTimeout(function(){
          $('.single-video #vjs_video_3').css('padding','0');
        }, 50);
        if($('.no_img_thumb').length > 0){
          setTimeout(function(){
            $('.no_img_thumb').find('.icon').click();
          }, 100);
        }
        if($('.single-video').length > 0 ) {
          $('html, body').animate({
            scrollTop: $('header').height() * 2
          }, 800);
        }
      }

    },


    'single_post': {
      init: function() {
        $('.page.open-post').each(function(index, el) {
          var $openPost = $(el);
          var $content = $openPost.find('.post-content');
          var contentOffset = $content.offset();

          $openPost.find('a.slide-down').click(function(event) {
            event.preventDefault();
            $('html, body').animate({
              scrollTop: contentOffset.top
            }, 800);
          });
        });
      }
    }
  };

  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Porto;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      UTIL.fire('common', 'finalize');
    }
  };

  
  // Load Events
  $(window).load(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
